
import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { PageMain } from './pagemain'
import { PageTokinosatoChirashi1 } from './pagetokinosatochirashi1'
import { PageTokinosatoChirashi2 } from './pagetokinosatochirashi2'
import { initializeGA, useTracking } from './GoogleAnalyticsUtil';

function AppInner(props) {
  useTracking()
  React.useEffect(() => {
    // GoogleAnalytics gtag.js読み込み
    // initializeGA();
  }, []);
  // GoogleAnalytics トラッキング
  return (
    <Switch>
      <Route exact path="/" component={PageMain} />
      <Route path="/tokinosatochirashi1" component={PageTokinosatoChirashi1} />
      <Route path="/tokinosatochirashi2" component={PageTokinosatoChirashi2} />
      <Route path="/tokinosatomain" component={PageTokinosatoChirashi1} />
    </Switch>
  );
}

const App = () => {
  return (
    <div className="App">
      <Router>
        <AppInner />
      </Router>
    </div>
  )
}
export default App;
