
import React from 'react';


export const PageMain = (props) => {
    return (
        <div>
            <header className="App-header">
                <h1 color="black">GA 解析用サイト</h1>
            </header>
        </div>
    )
}