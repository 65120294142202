import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// idで検索できるように埋め込むscript用の名前を定義
const trackingId = "G-NG0WPHM0NV"

export const useTracking = (
) => {
    const { listen } = useHistory()
    useEffect(() => {
        const unlisten = listen((location) => {
            if (!window.gtag) return
            if (!trackingId) {
                console.log(
                    'Tracking not enabled, as `trackingId` was not given and there is no `GA_MEASUREMENT_ID`.'
                )
                return
            }
            window.gtag('config', trackingId, { page_path: location.pathname })
        })
        return unlisten
    }, [listen])
}