
import React, { useEffect } from 'react';

export const PageTokinosatoChirashi1 = (props) => {
    useEffect(() => {
        window.location.href = 'https://en-gage.net/tokinosato-k_jobs/'
    }, []);
    return (
        <div>
            <header className="App-header">
            </header>
        </div>
    )
}